import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function InfoBlock ({ theme, content }) {

  const themeStyles = {
    dark: {
      div: 'bg-skoolie-green flex flex-col justify-center items-center px-[75px]',
      h2: 'text-white w-[367px] max-sm:text-[40px] max-sm:w-[267px]',
      h5: 'w-[367px] max-sm:text-[30px] max-sm:w-[267px]'
    },
    light: {
      div: 'bg-white flex flex-col justify-center items-center px-[75px]',
      h2: 'text-skoolie-green w-[367px] max-sm:text-[40px] max-sm:w-[267px]',
      h5: 'text-skoolie-green opacity-[.39] max-sm:text-[30px] w-[367px] max-sm:w-[267px]'
    },
    contact: {
      div: 'bg-white flex flex-col justify-center items-center px-[75px]',
      h2: 'text-black  w-[367px] max-sm:text-[40px] max-sm:w-[267px]',
      h5: 'text-skoolie-green opacity-[.39] w-[367px] max-sm:text-[30px] max-sm:w-[267px]',
      p: 'max-w-[450px]'
    }
  };

  return (
    <div className={'w-1/2 h-[600px] max-xl:w-screen ' + themeStyles[theme].div}>
      <h5 className={themeStyles[theme].h5}>{content.h5}</h5>
      <h2 className={'leading-tight mb-6 ' + themeStyles[theme].h2}>{content.h2}</h2>
      <p className={'text-[17px] w-[367px] max-sm:w-[267px] ' + themeStyles[theme].p}>{content.p}</p>
      {
        content.email && 
          (<a title='Skoolie email address' href={'mailto:' + content.email}
              className='w-[367px] max-sm:w-[267px] text-skoolie-green font-medium text-[18px] flex items-center mt-[28px]'>
            <FontAwesomeIcon icon={faEnvelope} className='text-skoolie-green size-6 mr-[13px]' />
            {content.email}
           </a>)
      }
    </div>
  )
}