import InfoBlock from '../shared/blocks/InfoBlock'
import BusBlock from '../shared/blocks/BusBlock'

export default function HowItWorks() {
  return (
    <div id="how-it-works" className='flex overflow-x-clip max-xl:flex-wrap'>
      <InfoBlock theme={'dark'} content={{ h5: 'Guest Experience',
        h2: 'How It Works',
        p: `It's like a coat check! Guests can pick out a
            locker and place anything inside that fits. They
            will then receive a card with their locker number
            and lock combination on it. Once they are ready
            to leave, they return with the card and collect
            their items.` }} />
      <BusBlock />
    </div>
  )
}