import NavBar from '../components/NavBar'
import Hero from '../components/Hero'
import HowItWorks from '../components/HowItWorks'
import EventDetails from '../components/EventDetails'
import Testimonial from '../components/Testimonial'
import EventPhotos from '../components/EventPhotos'
import FAQ from '../components/FAQ'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'

export default function Home() {
  return (
    <>
      <NavBar currentPage={'home'} />
      <Hero />
      <HowItWorks />
      <EventDetails />
      <Testimonial />
      <EventPhotos />
      <FAQ />
      <ContactUs />
      <Footer />
    </>
  )
}