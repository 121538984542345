import { useState } from 'react';
import emailjs from '@emailjs/browser';

function ContactForm({ theme }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    const form = e.target;

    const missingFieldMessages = {
      'first_name': 'Please give us your first name!',
      'last_name': 'Please give us your last name!',
      'email': 'Please fill out the email field.',
      'message': 'Don\'t forget to fill out a message!'
    };


    /**
     * Validate the form before sending to EmailJS.
     * @return {Boolean} Whether the form is valid.
     */
    function validateFields() {
      for (let element of form) {
        if (element.type !== 'submit' && element.value === '') {
          const message = missingFieldMessages[element.name];
          setStateMessage(message);
          setIsSubmitting(false);
          return false;
        }
      }
      return true;
    }
    const formIsValid = validateFields();
    if (!formIsValid) { return; }

    setStateMessage('Sending...');
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  const submitButtonId = theme !== 'lostandfound' ? 'general-submit-button' :
                                                    'lost-submit-button';

  // Contact form styles.
  const inputField = 'outline-none focus:ring focus:ring-2 focus:ring-skoolie-green focus:rounded-sm bg-[#ECECEC] placeholder-[#A0A0A0] p-[20px] pb-[17px] mb-[26px] max-sm:text-[14px]';
  const small = 'w-[250px] h-[52px] max-sm:w-[210px] max-sm:h-[47px] max-[500px]:w-[138px] ';
  const medium = 'w-[523px] h-[52px] max-sm:w-[443px] max-sm:h-[47px] max-[500px]:w-[300px] ';
  const large = 'w-[523px] h-[169px] max-sm:w-[443px] max-[500px]:w-[300px] ';
  const submitButton = 'w-[147px] h-[52px] rounded-[12px] hover:text-white ' +
    'hover:bg-skoolie-green font-bold text-[18px] float-right ' +
    'bg-white border-[3px] border-skoolie-green ' +
    'text-skoolie-green hover:cursor-pointer transition-all ' +
    'duration-200 hover:brightness-125 active:brightness-75';

  return (
    <form onSubmit={sendEmail} className='w-[523px] max-sm:w-[443px] max-[500px]:w-[300px]'>
      <input placeholder='First name' type="text" name="first_name" className={small + inputField + ' mr-[23px]'} />
      <input placeholder='Last name' type="text" name="last_name" className={small + inputField} />
      <input placeholder='Email' type="email" name="email" className={medium + inputField} />
      <textarea placeholder='Message' name="message" className={large + inputField} />
      <input id={submitButtonId} type="submit" value="Send" disabled={isSubmitting} className={submitButton} />
      {stateMessage && <p className='w-[360px] absolute'>{stateMessage}</p>}
    </form>
  );
}
export default ContactForm;