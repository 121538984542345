import React from 'react'
import { Link } from 'react-router-dom'
import { decodeHtmlEntities } from '../utility'

export default function FooterLink({ link }) {
  return (
    <Link to={link.href} className='text-[20px] max-md:text-[17px] max-sm:hidden'
      onClick={() => {
        const id = link.href.slice(2);
        const element = document.getElementById(id);
        element && element.scrollIntoView({ behavior: 'smooth' });

        // If we're on navigating home, jump to the top.
        !link.href.includes('#') && window.scroll(0, 0);
      }}>
      <h6 className='text-white'>{decodeHtmlEntities(link.name)}</h6>
    </Link>
  )
}