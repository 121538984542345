import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { testimonials } from '../shared/utility'
import WhiteBus from '../assets/skoolie-logo-white.png'
import { decodeHtmlEntities } from '../shared/utility'
import { useState } from 'react'

export default function Testimonial() {
  const [index, setIndex] = useState(0);
  const [text, setText] =
      useState(decodeHtmlEntities(testimonials[index].statement));


  /**
   * Grabs the next the testimonial based on which arrow the user selects.
   * @param {Number} direction Will either be 1 or -1, reflecting whether
   *     to go forward or backward in the testimonial list.
   */
  function getText(direction) {
    let newIndex = index + direction;

    // If we're at the beginning of the testimonials, loop to the end.
    if (newIndex < 0) { newIndex = testimonials.length - 1 };

    // If we're at the end of the testimonials, loop to the beginning.
    if (newIndex > testimonials.length - 1) { newIndex = 0 };

    setIndex(newIndex);
    setText(decodeHtmlEntities(testimonials[newIndex]?.statement));
  }

  const chevronStyles = 'text-white size-9 hover:cursor-pointer active:scale-90 ' +
                        'max-sm:mx-[10px]';

  return(
    <div className="relative overflow-y-clip bg-skoolie-green h-[254px] flex justify-center items-center">
      <img alt='White bus logo' src={WhiteBus} className='absolute top-[-4px] left-[216px] rotate-180 w-[155px] select-none max-lg:w-[115px] max-lg:left-[40px]' />
      <img alt='White bus logo' src={WhiteBus} className='absolute bottom-[-4px] right-[212px] w-[155px] select-none max-lg:w-[115px] max-lg:right-[40px]' />
      <FontAwesomeIcon icon={faChevronLeft} className={chevronStyles} onClick={() => { getText(-1) }} />
      <p className='text-white text-[32px] leading-tight text-center w-[537px] mx-[200px] select-none max-lg:text-[28px] max-lg:mx-[40px]'>{text}</p>
      <FontAwesomeIcon icon={faChevronRight} className={chevronStyles} onClick={() => { getText(1) }} />
    </div>
  )
}