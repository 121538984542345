import NavBar from '../components/NavBar'
import LostAndFoundContact from '../components/LostAndFoundContact'
import LostFAQ from '../components/LostFAQ'
import Footer from '../components/Footer'

export default function LostAndFound() {
  return (
    <>
      <NavBar currentPage={'lostandfound'} />
      <LostAndFoundContact />
      <LostFAQ />
      <Footer currentPage={'lostandfound'} />
    </>
  )
}