import BusLine from '../../components/BusLine'
import BusWheel from '../../components/BusWheel'

export default function BusBlock () {
  return (
    <div className="relative w-1/2 h-[600px] max-xl:w-[100vw] bg-skoolie-green p-[20px] pb-[60px] overflow-y-clip">
      <BusLine styles={{ marginLeft: '23px', marginTop: '47px' }} />
      <BusLine styles={{ marginLeft: '-80px' }} />
      <BusLine styles={{ marginLeft: '23px' }} />
      <BusWheel />
      <span className="absolute top-[33px] left-[229px] bg-bus-sign bg-contain bg-center bg-contain w-[339px] h-[461px] max-xl:top-[50%] max-xl:left-[50%] max-xl:translate-x-[-50%] max-xl:translate-y-[-60%]"></span>
    </div>
  )
}