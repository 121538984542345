import { links } from './../shared/utility'
import DefaultLink from './../shared/links/DefaultLink'

export default function LostAndFoundCTA() {
  return (
    <div className='flex p-[20px] w-[451px] max-md:w-[390px] max-[400px]:w-[290px] h-[129px] max-md:h-[115px] max-[400px]:h-[93px] ml-[15px] mb-[22px] bg-white rounded-[8px] font-urbanist font-semibold'>
      <span className='self-end bg-lost-and-found-icon bg-contain bg-center bg-no-repeat w-[69px] max-md:w-[55px] h-[83px] max-[400px]:h-[53px] ml-2 mr-3 mb-1'></span>
      <div className='self-top'>
        <h3 className='text-[24px] max-md:text-[18px] max-[400px]:text-[14px] w-[322px] max-md:w-[276px] max-[400px]:w-[186px]'>Left your items on the bus?</h3>
        <p className='text-[15px] max-md:text-[13px] max-[400px]:text-[9px] w-[322px] max-md:w-[276px] max-[400px]:w-[186px] leading-tight'>
          No problem! <DefaultLink link={links[links.length - 1]} text={'Send us a message'} /> and we'll make sure to get them back to you safe and sound.
        </p>
      </div>
    </div>
  )
}