import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { decodeHtmlEntities } from '../utility'

export default function NavBarLink({ link }) {
  const location = useLocation()

  const isLinkActive = () => {
    return link.name === 'Lost &amp; Found' &&
           location.pathname.includes(link.href);
  }

  return (
    <Link to={link.href} className={'relative flex items-center justify-center hover:underline decoration-2 underline-offset-4 decoration-skoolie-green max-md:hidden ' +
      (isLinkActive() && 'underline')} onClick={() => {
        const id = link.href.slice(2);
        const element = document.getElementById(id);
        element && element.scrollIntoView({ behavior: 'smooth' });
      }}>
      <h6>{decodeHtmlEntities(link.name)}</h6>
    </Link>
  )
}