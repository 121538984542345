import RockLansing from '../assets/rock-lansing.jpg'
import GrBeerFest from '../assets/gr-beerfest.jpg'
import MacAndCheeseFest from '../assets/mac-and-cheese-fest.jpg'
import LansingBeerFest2 from '../assets/lansing-beerfest-2.jpg'
import LockerWithLights from '../assets/lockers-with-lights-at-night.jpg'
import LockedLockers from '../assets/lockers-locked.jpg'
import BbqAndBeerSign from '../assets/bbq-and-beer-bash-sign.jpg'
import NameSuggestion from '../assets/name-suggestion.jpg'
import TacoAndTequila from '../assets/gr-taco-and-tequila.jpg'


export const links = [
  { name: "How It Works", href: "/#how-it-works" },
  { name: "Event Details", href: "/#event-details" },
  { name: "Photos", href: "/#event-photos" },
  { name: "Contact", href: "/#contact-us" },
  { name: "Lost &amp; Found", href: "/lostandfound" }
];


export const lostLinks = [
  { name: 'Home', href: '/' },
  { name: 'Lost &amp; Found', href: '/lostandfound' }
]


export const testimonials = [
  { statement: 'Taco &amp; Tequila Festival | July 27th, 2024' },
  { statement: 'Mac and Cheese Festival | June 20th, 2024' },
  { statement: 'Rock Lansing | May 2024' },
  { statement: 'Beerfest at the Ballpark | April 2024' }
];


export const eventPhotos = [
  { path: TacoAndTequila, text: 'Taco & Tequila', subtext: '4-8pm / Riverside Park / July 27th' },
  { path: RockLansing, text: 'Rock Lansing', subtext: 'First music festival!' },
  { path: GrBeerFest, text: 'Beerfest at the Ballpark', subtext: 'April 26th' },
  { path: MacAndCheeseFest, text: 'Mac and Cheese Festival', subtext: 'June 20th' },
  { path: LansingBeerFest2, text: 'Lansing Beerfest', subtext: 'April 2024' },
  { path: LockerWithLights, text: 'Outfitted with lights', subtext: '' },
  { path: LockedLockers, text: 'Lock up your stuff', subtext: '' },
  { path: BbqAndBeerSign, text: 'BBQ & Beer Bash', subtext: 'February 17th' },
  { path: NameSuggestion, text: 'Bus in the sun', subtext: '' },
];


export const patronFaqs = [
  {
    question: 'How much are lockers?',
    answer: `The price of lockers vary based on each event and incorporate the wishes of the event host.`
  },
  { 
    question: 'Are there different sizes?',
    answer: `We currently offer one size. They are 18"x12"x11"`
  },
  { 
    question: 'Can I store alcohol or restricted items?',
    answer: `Restricted items are based on the wishes of the event host and wether the bus is located inside the venue or outside the doors.`
  },
  { 
    question: 'How long does the bus stay at events?',
    answer: `The bus will remain for 30 minutes after the end of the event, unless otherwise posted.`
  },
  { 
    question: 'What happens if I forget to pick up my stuff?',
    answer: `All lockers come with our contact information, or submit a request on our website.  All items will be shipped back at the owners expense.`
  },
];


export const eventPlannerFaqs = [
  { question: 'How will the bus help my Event?',
    answer: `The bus is perfect for a bag check if your event has restricted items.  But it can also be used as a VIP perk for select guests. For long events it also offers a convenient place for guests to store their belongings.`
  },
  {
    question: 'How do you price the lockers?',
    answer: `We will discuss price based on length of event, type of event and type of service the bus provides.`
   },
  {
    question: 'How Large is the Bus?',
    answer: `The Bus is 33' long and 11' tall.  It weighs 18,000lbs.`
  },
  {
    question: 'Is the Bus staffed?',
    answer: `The Bus is staffed for the entirety of the event.`
  }
];


/**
 * Takes a given string and decodes HTML entities from it.
 * @param {String} str The string to decode HTML entities from.
 * @returns {String} Returns the decoded string.
 */
export let decodeHtmlEntities = (str) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
}