import { patronFaqs, eventPlannerFaqs } from '../shared/utility'
import FaqDropdown from '../shared/dropdowns/FaqDropdown'

export default function FAQ() {
  return (
    <div className='px-[320px] pb-[80px] max-xl:px-[200px] max-lg:px-[100px] max-md:px-[50px] max-sm:px-[5px]'>
      <h2 className='text-black text-center my-[30px] max-md:text-[36px]'>Frequently Asked Questions</h2>

      <h5 className='text-skoolie-green opacity-[.39] pl-[30px] pb-[30px]'>Patrons</h5>
      <div className='flex flex-col gap-[20px]'>
        {patronFaqs.map((faq, index) => {
          return <FaqDropdown faq={faq} key={index} />
        })}
      </div>

      <h5 className='text-skoolie-green opacity-[.39] pl-[30px] pb-[30px] mt-[60px]'>Event Planners</h5>
      <div className='flex flex-col gap-[20px]'>
        {eventPlannerFaqs.map((faq, index) => {
          return <FaqDropdown faq={faq} key={index} />
        })}
      </div>
    </div>
  )
}