import ModalImage from 'react-modal-image'

function selectModalImage(e) {
  let image = e.target.parentElement.children[0].children[0];
  !image && (image = e.target.parentElement.parentElement.children[0].children[0]);

  image.click();
}

export default function EventPhoto({ photo }) {
  return (
    <span className="relative">
      <ModalImage alt={photo.text} small={photo.path} large={photo.path} hideDownload hideZoom className="object-cover w-[265px] h-[265px] max-w-none max-md:w-[180px] max-md:h-[180px]"  />
      <span onClick={ selectModalImage } className="absolute top-0 w-[265px] h-[265px] flex flex-col justify-center items-center bg-black opacity-0 hover:opacity-[.77] hover:cursor-pointer max-md:w-[180px] max-md:h-[180px]">
        <p className="text-white text-[17px] leading-none max-md:text-[14px]">{photo.text}</p>
        <p className="text-white font-light text-[17px] max-md:text-[11px]">{photo.subtext}</p>
      </span>
    </span>
  )
}