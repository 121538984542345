import GreenBus from '../assets/skoolie-logo-color.png'
import { links, lostLinks } from '../shared/utility'
import FooterLink from '../shared/links/FooterLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons'

const currentYear = new Date().getFullYear();

export default function Footer({ currentPage }) {
  const lostandfound = currentPage === 'lostandfound';
  const footerLinks = lostandfound ? lostLinks : links;


  return (
    <div>
      <img src={GreenBus} alt='Green Skoolie bus' className='w-[368px] ml-[40px] mt-[40px] max-sm:w-[288px]' />
      <div className='w-screen h-[110px] bg-skoolie-green mt-[-9px] flex justify-center items-center gap-[47px] max-md:gap-[38px] max-sm:h-[70px]'>
        {footerLinks.map((link, index) => {
          return <FooterLink link={link} key={index} />
        })}
        <a className='absolute right-[100px] max-xl:right-[10px] max-sm:relative max-sm:right-0' href='https://www.facebook.com/profile.php?id=100090204338032' rel='noopener'>
          <FontAwesomeIcon icon={faSquareFacebook} className='text-white text-[37px] max-md:text-[30px]' />
        </a>
      </div>
      <span className='flex justify-center items-center pt-[27px] pb-[27px] text-[12px] max-sm:text-[9px] text-skoolie-green'>
        Copyright © {currentYear} Skoolie Locker Rentals • Designed by
        <a className='ml-1 hover:underline underline-offset-2' href='https://thezach.website/' rel='noopener'>
          Zach Vollink
        </a>
      </span>
    </div>
  )
}