import { patronFaqs } from '../shared/utility'
import FaqDropdown from '../shared/dropdowns/FaqDropdown'

export default function LostFAQ() {
  return (
    <div className='py-[100px] px-[320px] pb-[80px] max-xl:px-[200px] max-lg:px-[100px] max-md:px-[50px] max-sm:px-[5px]'>
      <h2 className='text-black text-center my-[30px] max-md:text-[36px]'>Frequently Asked Questions</h2>

      <div className='flex flex-col gap-[20px]'>
        {patronFaqs.map((faq, index) => {
          return <FaqDropdown faq={faq} key={index} />
        })}
      </div>
    </div>
  )
}