import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default function FaqDropdown({ faq }) {
  let [open, setOpen] = useState(false);

  function clickHandler() {
    setOpen(!open);
  }

  return (
    <div className={'faq-dropdown relative w-full h-[43px] max-sm:h-[40px] max-[330px]:h-[38px] white border-[3px] border-skoolie-green rounded-[10px] hover:cursor-pointer overflow-hidden transition-[height] duration-300' + (open ? ' open' : ' closed')} onClick={clickHandler}>
      <p className='text-white text-[15px] max-sm:text-[14px] max-[370px]:text-[12px] max-[330px]:text-[11px] bg-skoolie-green px-[30px] py-[8px] select-none'>{faq.question}</p>
      <FontAwesomeIcon icon={faChevronDown} className='absolute text-white w-[23px] top-[-1px] right-[21px] text-[37px]' />
      <p className="py-[20px] px-[30px] pointer-events-none">{faq.answer}</p>
    </div>
  )
}