import MediaBlock from '../shared/blocks/MediaBlock';
import InfoBlock from '../shared/blocks/InfoBlock';

export default function EventDetails() {
  return (
    <div id='event-details' className='flex max-lg:flex-wrap'>
      <MediaBlock />
      <InfoBlock theme={'light'} content={{ h5: 'Rent the bus',
        h2: 'Event Details',
        p: `Ideal for door security or VIPs.
            We provide a quote for your event and agree to a
            locker price. Every locker rented comes off of the
            quote. This leaves the chance that you don't have
            to pay for the bus!` }} />
    </div>
  )
}