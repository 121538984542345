import InfoBlock from '../shared/blocks/InfoBlock'
import ContactBlock from '../shared/blocks/ContactBlock'

export default function LostAndFoundContact() {
  return (
    <div className='flex pt-[140px] max-xl:flex-wrap'>
      <InfoBlock theme={'contact'} content={{
        h5: 'Unclaimed items?',
        h2: 'Let\'s get your stuff back to you!',
        p: `If you left something behind, don't worry!
        We're committed to helping you collect your
        belongings. Simply contact us with your locker
        number and event details, and we'll ensure your
        items are returned promptly and securely. Your
        convenience and peace of mind are our top
        priorities.`,
        email: 'contact@skoolielockers.com' }} />
      <ContactBlock theme={'lostandfound'} />
    </div>
  )
}