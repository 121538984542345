import FullLogo from './FullLogo'
import { links, lostLinks } from './../shared/utility'
import NavBarLink from './../shared/links/NavBarLink'

export default function NavBar({ currentPage }) {
  const lostandfound = currentPage === 'lostandfound';
  const navLinks = lostandfound ? lostLinks : links;

  return (
    <>
      <div className="w-screen bg-skoolie-green h-[10px] z-20 absolute"></div>
      { (lostandfound) ? <a href='/'><FullLogo /></a> : <FullLogo />}
      <div className='absolute top-0 bg-white w-screen h-[73px] flex items-center pt-[10px] justify-end pr-20 max-[950px]:pr-10 shadow-bottom max-[1150px]:h-[63px]'>
        <div className='inline-flex gap-14 font-urbanist font-semibold text-[18px] max-[1150px]:text-[16px] max-[1150px]:gap-8 max-[950px]:text-[14px] max-[950px]:gap-6'>
          {navLinks.map((link, index) => {
            return <NavBarLink link={link} key={index} />
          })}
        </div>
      </div>
    </>
  )
}