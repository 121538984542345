import { eventPhotos } from '../shared/utility'
import EventPhoto from './EventPhoto'

export default function EventPhotos() {
  return (
    <div id='event-photos' className="py-16 px-16 max-md:px-12 max-sm:px-2 bg-white flex flex-wrap">
      <h2 className="block pl-[36px] pr-[81px] text-black border-b-skoolie-green border-b-[3px] pb-0 leading-[.69] mb-[54px] select-none max-md:text-[36px] max-md:pr-[30px] max-md:pl-[10px]">
        Event Photos
      </h2>
      <div className='flex w-full flex-wrap justify-center'>
        {eventPhotos.map((photo, index) => {
          return <EventPhoto photo={photo} key={index} />
        })}
      </div>
    </div>
  )
}