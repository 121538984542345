import ContactForm from '../ContactForm'
import WhiteBus from '../../assets/skoolie-logo-white.png'

export default function ContactBlock({ theme }) {
  let divStyles = (theme === 'home') ? 'w-1/2 h-[600px] flex flex-col justify-center items-center px-[75px] max-xl:w-screen' :
      'relative w-1/2 h-[600px] mr-[60px] max-xl:w-screen max-xl:flex max-xl:justify-center max-xl:mr-[0]';
  let div2Styles = (theme === 'home') ? '' :
      'w-[653px] px-[60px] py-[40px] pb-[100px] bg-skoolie-green rounded-[8px] max-[653px]:rounded-none';
  let h2Styles = (theme === 'home') ? 'text-black mb-[44px] max-sm:text-[36px] max-sm:mb-[36px]' :
      'text-white mb-[44px]';

  const busStyles = 'absolute w-[155px] rotate-180 left-[417px] top-[-4px] max-xl:left-[60%]'

  return (
    <div className={divStyles}>
      { (theme === 'lostandfound') && 
        <img src={WhiteBus} alt='White bus logo' className={busStyles} />}
      <div className={div2Styles}>
        <h2 className={h2Styles}>Contact Us</h2>
        <ContactForm theme={theme} />
      </div>
    </div>
  )
}