import InfoBlock from '../shared/blocks/InfoBlock'
import ContactBlock from '../shared/blocks/ContactBlock'

export default function ContactUs() {
  return (
    <div id='contact-us' className='flex max-xl:flex-wrap'>
      <InfoBlock theme={'contact'} content={{
        h5: 'Let\'s work together',
        h2: 'Get lockers for your next event!',
        p: `Whether you're hosting a conference,
        festival, or any other event, our lockers
        provide a secure place for attendees to
        store their belongings. Reach out to us
        today to discuss how we can meet your
        event's needs with our flexible and
        reliable service. Let us help you create
        a memorable event where guests can focus
        on enjoying the moment without worrying
        about their personal items.`,
        email: 'contact@skoolielockers.com' }} />
      <ContactBlock theme={'home'} />
    </div>
  )
}