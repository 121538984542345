import LostAndFoundCTA from "./LostAndFoundCTA"

export default function Hero() {
  return (
    <div className="w-screen max-h-[820px] max-md:max-h-[580px] max-sm:max-h-[500px] overflow-clip">
      <span className="flex items-end bg-skoolie-bus bg-cover bg-no-repeat bg-center w-screen h-screen max-h-[820px] max-md:max-h-[580px] max-sm:max-h-[500px]">
        <LostAndFoundCTA />
      </span>
    </div>
  )
}