import TagManager from "react-gtm-module";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import LostAndFound from "./pages/LostAndFound";

const tagManagerArgs = {
  gtmId: 'GTM-P922QVD6'
}

function App() {
  TagManager.initialize(tagManagerArgs);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="lostandfound" element={<LostAndFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
